<style lang="scss"></style>

<template>
  <div class="brands-box-outer">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="img-box-logo">
            <a :href="brandWebsite" target="_blank"><img :src="brandImgLogo" :alt="brandAltText" class="img-fluid" :class="brandLogoClass"/></a>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10 col-md-10">
          <div class="txt-inner">
            <h1>{{ brandHeadline }}</h1>
            <div class="brandLongText" v-html="brandLongText"></div>
            <div class="gruendung" v-if="brandGruendung && brandGruendung != '0'">
              {{ $t('General.gruendung') }}<span>{{ brandGruendung }}</span>
            </div>
          </div>
          <div class="txt-bottom" v-if="brandWebsiteText">
            <a :href="brandWebsite" target="_blank"
              ><span>{{ brandWebsiteText }}</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    brandImgLogo: {
      type: String,
      required: true,
    },
    brandLogoClass: {
      type: String,
    },
    brandAltText: {
      type: String,
      required: true,
    },
    brandHeadline: {
      type: String,
      required: true,
    },
    brandGruendung: {
      type: String,
      default: '0',
    },
    brandLongText: {
      type: String,
      required: true,
    },
    brandEmail: {
      type: String,
    },
    brandEmailText: {
      type: String,
    },
    brandWebsite: {
      type: String,
      required: true,
    },
    brandWebsiteText: {
      type: String,
      required: true,
    },
  },
});
// https://vuejs.org/v2/guide/components-props.html
</script>
